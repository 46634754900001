import { FaCheck, FaInfoCircle, FaTimes } from 'react-icons/fa';
import Tooltip from '../../atoms/Tooltip';
import SelectFilter from '../../organisms/SelectFilter';
import PageSearch from '../../molecules/PageSearch';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ASearch from '../../organisms/ASearch';
import ReferralOriginIcon from '../../ions/ReferralOriginIcon';
import AnalysisRecommendationIcon from '../../ions/AnalysisRecommendationIcon';
import PageList from '../../templates/PageList';
import LCBalanceCalcValue from '../../molecules/LCBalanceCalcValue';
import ReferralAdditionalContext from '../../ions/ReferralAdditionalContext';
import ReferralCreditLimitDivideCreditLimitRural from '../../ions/ReferralCreditLimitDivideCreditLimitRural';

type Referral = {
    id: number,
    referralUUId: string,
    referralId: number,
    referralDate: string,
    socialId: string,
    name: string, //fullname
    recurrence: boolean,
    partner: string,
    partnerId: number,
    origin: string,
    originId: number,
    originEnvironment: string,
    guaranteePercentage: number,
    scorePartner: number,
    scoreAgrolend: number,
    creditLimit: number,
    creditLimitFormated: string,
    recommendationCode: string,
    recommendation: string,
    status: string,
    partnerLimit: number,
    limitCreditDivideCreditRuralInPercentual:string
    creditRuralFormatedValue:string,
    desiredValue: string,
    observation: string
}

const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/referrals`;
const BASE_SETTLEMENT_URL = `${process.env.REACT_APP_SETTLEMENT_API_URL}/settlement`;

const PartnerAnalysisListPage = () => {

    const navigate = useNavigate();

    const optionsStatus = [{
        value: 'approved',
        label: 'Aprovado'
    }, {
        value: 'not-approved',
        label: 'Reprovado'
    }, {
        value: 'pending',
        label: 'Pendente'
    }, {
        value: 'processing',
        label: 'Em Processamento'
    }, {
        value: 'error',
        label: 'Erro de Processamento'
    }]

    const { page, pageSize, field: fieldSorted,
        order: orderSorted, filter }: any = useParams();

    let txtPartnerName = '';
    let txtRecomendation = '';
    let txtSocialId = '';

    if (filter)
        [txtPartnerName = '', txtRecomendation = '', txtSocialId = ''] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));

    const url = BASE_REFERRAL_URL + `/partner-analysis-list?search=${txtSocialId}`
        + `&page=${page}&pageSize=${pageSize}&recomendation=${txtRecomendation}&partner=${txtPartnerName}`;


    const renderRows = (rows: Referral[]) => {
        return rows.map((row: Referral, index: number) => (
            <tr key={row.referralId}
                className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border border-gray-300`}>
                <td className="py-2 px-2">{row.referralId}</td>
                <td className="py-2 px-2">{row.referralDate}</td>
                <td className="py-2 px-2 whitespace-nowrap">{row.socialId}</td>
                <td className="py-2 px-2 truncate max-w-0">
                    {row.name}
                </td>
                <td className="py-2 px-2" align="center">
                    {row.recurrence
                        ?
                        <Tooltip content="Sim">
                            <FaCheck style={{ color: 'cornflowerblue' }} />
                        </Tooltip>
                        :
                        <Tooltip content="Não">
                            <FaTimes style={{ color: 'rgb(242, 5, 37)' }} />
                        </Tooltip>
                    }
                </td>

                <td className="py-2 px-2 truncate max-w-0" align="center" style={{ maxWidth: '5ch' }}>
                    <ReferralAdditionalContext
                        text={row.observation}
                    />
                </td>

                <td className="py-2 px-2 truncate max-w-0">
                    {row.partner}
                </td>

                <td className="py-2 px-2" align="center">
                    <ReferralOriginIcon
                        id={row.originId}
                        title={row.origin}
                        environment={row.originEnvironment}
                    />
                </td>

                <td className="py-2 px-2">
                    {row.guaranteePercentage}%
                </td>

                <td className="py-2 px-2 text-right">
                    {row.scorePartner !== null ? row.scorePartner.toFixed(2) : '0.00'}
                </td>

                <td className="py-2 px-2" style={{ verticalAlign: 'middle' }} align="right">

                    <div className="flex items-center text-right justify-end">
                        {row.scoreAgrolend}
                        <Link to={`agrolend-score/${row.referralUUId}`} className="ml-2">
                            <FaInfoCircle style={{ color: 'cornflowerblue' }} />
                        </Link>
                    </div>
                </td>

                <td className="py-2 px-2" align="center">
                    <Link to={`biro-list/${row.referralUUId}/${row.partnerId}`}>
                        <FaInfoCircle style={{ color: 'cornflowerblue' }} />
                    </Link>
                </td>

                <td className="py-2 px-2">
                    <div className="flex  items-center justify-end">
                        <span className="mr-2">{row.desiredValue}</span>
                    </div>
                </td>

                <td className="py-2 px-2">
                    <div className="flex  items-center justify-end">
                        <span className="mr-2">{row.creditLimitFormated}</span>
                        <AnalysisRecommendationIcon
                            code={row.recommendationCode}
                            description={row.recommendation} />
                    </div>
                </td>

                <td className="py-2 px-2">
                    <div className="flex  items-center justify-center">
                        <ReferralCreditLimitDivideCreditLimitRural creditLimitFormated={row.creditLimitFormated}
                                                                   limitCreditDivideCreditRuralInPercentual={row.limitCreditDivideCreditRuralInPercentual}
                                                                   recommendation={row.recommendationCode}
                                                                   creditRuralFormatedValue={row.creditRuralFormatedValue} />
                    </div>
                </td>

                <td className="py-2 px-2">
                    <div className="flex items-center justify-end">
                        <span className="mr-2">
                            <LCBalanceCalcValue
                                url={`${BASE_SETTLEMENT_URL}/farmer/debt/${row.socialId}`}
                                lessValue={row.creditLimit}
                                jsonField="value"
                                formatBRL={true}
                                toolTip="Operações em aberto: $$"
                            />
                        </span>
                    </div>
                </td>

                <td className="py-2 px-2" align="center">
                    <Link to={`referral-detail/${row.referralUUId}`}>
                        <FaInfoCircle style={{ color: 'cornflowerblue' }} />
                    </Link>
                </td>
            </tr>
        ));
    }

    const pageChanged = (page: number) => {
        page = page - 1;
        navigate(urlNaviage(page, pageSize, fieldSorted, orderSorted, txtSocialId, txtRecomendation, txtPartnerName), { replace: true });
    };

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, txtSocialId, txtRecomendation, txtPartnerName), { replace: true });
    }

    const pageSorted = (field: keyof Referral | string, order: 'ASC' | 'DESC') => {
        navigate(urlNaviage(page, pageSize, field, order, txtSocialId, txtRecomendation, txtPartnerName), { replace: true });
    }

    const socialIdSearch = (search: string) => {
        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, search, txtRecomendation, txtPartnerName), { replace: true });
    }

    const partnerSearch = (partnerName: string) => {
        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, txtSocialId, txtRecomendation, partnerName), { replace: true });
    }

    const filterRecommendation = (recomendation: string) => {
        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, txtSocialId, recomendation, txtPartnerName), { replace: true });
    }

    const onFilterClean = () => {
        txtPartnerName = '';
        txtRecomendation = '';
        txtSocialId = '';
        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, '', '', ''), { replace: true });
    }

    const urlNaviage = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        socialId: string, recomendation: string, partner: string) => {
        return `/referrals/partner-analysis/${page}/${pageSize}/${fieldSorted}/${orderSorted}/p=${partner};r=${recomendation};s=${socialId};`
    }

    return (

        <PageList
            pageTitle='Lista de Pedidos - Revenda'
            url={url}
            breadcrumb={[{ label: 'Pedidos da revenda', url: "#" }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            itemsLabel='pedidos'
        >
            {{
                filterSlot: (
                    <div className="grid grid-cols-3 gap-1">
                        <ASearch
                            id="src-partner-name"
                            value={txtPartnerName}
                            label="Procurar por revenda..."
                            onSearch={partnerSearch}
                        />

                        <SelectFilter
                            id="slc-status"
                            label="Recomendação ..."
                            value={txtRecomendation}
                            onChange={filterRecommendation}
                            defaultVoidOption={true}
                            options={optionsStatus}
                        />

                        <PageSearch
                            value={txtSocialId}
                            label='Procurar por CPF...'
                            onSearch={socialIdSearch}
                            filterCleanVisible={true}
                            filterCleanEnabled={(txtPartnerName || txtRecomendation || txtSocialId) ? true : false}
                            configureVisible={false}
                            onFilterClean={onFilterClean}
                        />
                    </div>),

                controlSlot: (null)
            }}
        </PageList>
    );
};

export default PartnerAnalysisListPage;